<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item v-if="isShowChooseOrganization">
        <v-select
          v-model="organizationId"
          label="name"
          :options="organizations"
          :reduce="option => option.id"
          :clearable="false"
          :loading="isLoading"
          style="min-width: 200px;"
          @input="onSetOrganizationSystemAdmin"
        />
      </b-nav-item>
      <notification-dropdown />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user ? user.fullName : '' }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="ulrAvatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="showModalProfile()"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Thông tin</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$bvModal.show('changePasswordModal')"
        >
          <feather-icon
            size="16"
            icon="LockIcon"
            class="mr-50"
          />
          <span>Đổi mật khẩu</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="scoreLockPasswordUpdatable"
          link-class="d-flex align-items-center"
          @click="$bvModal.show('changeScoreLockPasswordModal')"
        >
          <feather-icon
            size="16"
            icon="LockIcon"
            class="mr-50"
          />
          <span>Đổi mật khẩu khóa điểm</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="isSystemAdmin"
          link-class="d-flex align-items-center"
          @click="$bvModal.show('changeRecoverDataPasswordModal')"
        >
          <feather-icon
            size="16"
            icon="LockIcon"
            class="mr-50"
          />
          <span>Đổi mật khẩu phục hồi dữ liệu</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="onLogout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Thoát</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-modal
      id="changePasswordModal"
      body-class="position-static"
      title="Thay đổi mật khẩu"
      :no-close-on-backdrop="!(user && user.changePasswordAt)"
      :no-close-on-esc="!(user && user.changePasswordAt)"
      :hide-header-close="!(user && user.changePasswordAt)"
      :hide-footer="true"
    >
      <ChangePassword @onSucceed="onSucceed" />
    </b-modal>
    <ChangeScoreLockPassword />
    <ChangeRecoverDataPassword />
    <b-modal
      id="user-info"
      ref="user-info"
      title="Thông tin cá nhân"
      size="lg"
      :hide-footer="true"
      no-close-on-backdrop
    >
      <FormEmployee
        :data-send-form="userInfo"
        @saveDataForm="saveDataEmployee"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BNavItem,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { getUser, isUserLoggedIn, getLoginType } from '@/auth/utils'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import ChangePassword from '@/views/changePassword/Index.vue'
import FormEmployee from '@/views/admin/category/employee/FormEmployee.vue'
import ChangeScoreLockPassword from '@/views/changePassword/ChangeScoreLockPassword.vue'
import ChangeRecoverDataPassword from '@/views/changePassword/ChangeRecoverDataPassword.vue'
import { PermissionCode, ResourceCode } from '@/const/code'
import useJwt from '@/auth/jwt/useJwt'
import { LOGIN_TYPE } from '@/const/type'

export default {
  components: {
    ChangeScoreLockPassword,
    ChangeRecoverDataPassword,
    NotificationDropdown,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BNavItem,
    vSelect,

    // Navbar Components
    DarkToggler,
    ChangePassword,
    FormEmployee,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      organizationId: null,
      user: getUser(),
      userInfo: {},
      ulrAvatar: '',
    }
  },
  computed: {
    ...mapGetters({
      organizations: 'factOrganization/dataSources',
      employeeDetail: 'employee/employeeDetail',
    }),
    isSystemAdmin() {
      return isSystemAdmin()
    },
    isShowChooseOrganization() {
      return isUserLoggedIn() && isSystemAdmin()
    },
    scoreLockPasswordUpdatable() {
      return this.user && this.user.scoreLockPassword > 0
        && hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.SCORE_LOCK_PASSWORD)
    },
  },
  async created() {
    if (isUserLoggedIn()) {
      this.organizationId = this.user.orgId
      if (this.isSystemAdmin) {
        this.isLoading = true
        try {
          await this.getOrganizationOptions({ currentPage: 1, itemsPerPage: 100 })
          if (!this.user.avatar) {
            // eslint-disable-next-line global-require
            this.ulrAvatar = require('@/assets/images/avatars/default_avatar.jpg')
          } else {
            const resLink = await this.getLinkAvatar(this.user.avatar)
            if (resLink.isSuccessful) {
              this.ulrAvatar = resLink.data
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    }
  },
  mounted() {
    this.checkFirstLogin()
  },
  methods: {
    ...mapActions({
      getOrganizationOptions: 'factOrganization/getDataSources',
      setOrganizationForSystemAdmin: 'auth/setOrganizationForSystemAdmin',
      verify: 'auth/verify',
      getEmployeeDetail: 'employee/getEmployeeDetail',
      getLinkAvatar: 'employee/getLinkAvatar',
      saveEmployee: 'employee/updateEmployee',
    }),
    onLogout() {
      const loginType = getLoginType()
      if (loginType === LOGIN_TYPE.NORMAL) {
        useJwt.logoutNormal()
      } else {
        useJwt.logoutSso()
      }
    },
    async onSetOrganizationSystemAdmin(value) {
      this.isLoading = true
      try {
        const response = await this.setOrganizationForSystemAdmin({ organizationId: value })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$router.go(0)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    checkFirstLogin() {
      if (!this.user.changePasswordAt) {
        this.$swal
          .fire({
            title: 'Thông báo!',
            text: 'Vui lòng thay đổi mật khẩu mặc định để bảo vệ thông tin cũng như quyền lợi của mình.!',
            confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
            cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            allowEscapeKey: false,
            allowOutsideClick: false,
            buttonsStyling: false,
          })
          .then(async result => {
            if (result.isConfirmed) {
              this.$bvModal.show('changePasswordModal')
            }
          })
      }
    },

    async showModalProfile() {
      await this.getEmployeeDetail({ id: this.user.employeeId })
      this.userInfo = {
        id: this.user.employeeId,
        lastName: this.employeeDetail.lastName,
        name: this.employeeDetail.firstName,
        identifyCode: this.employeeDetail.identifyCode,
        birthday: this.employeeDetail.birthday,
        email: this.employeeDetail.email,
        phone: this.employeeDetail.phone,
        departmentId: this.user.departmentId,
        titleId: this.employeeDetail.titleId,
        orderNo: '',
        address: this.employeeDetail.address,
        status: parseInt(this.employeeDetail.status, 10),
        pathFileAvatar: this.employeeDetail.avatar,
        createAccount: 0,
        roleIds: this.user.roles.map(item => (item.id)),
        passportCode: this.employeeDetail.passportCode,
        permanentResidence: this.employeeDetail.permanentResidence,
        birthPlace: this.employeeDetail.birthPlace,
        dateEnter: this.employeeDetail.dateEnter,
        provinceCode: this.employeeDetail.provinceCode,
        districtCode: this.employeeDetail.districtCode,
        ethnicCode: this.employeeDetail.ethnicCode,
        religionCode: this.employeeDetail.religionCode,
        academicDegreeId: this.employeeDetail.academicDegreeId,
        academicRankId: this.employeeDetail.academicRankId,
        hrmCode: this.employeeDetail.hrmCode,
      }
      this.$bvModal.show('user-info')
    },

    async saveDataEmployee(data) {
      try {
        await this.saveEmployee(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cập nhật thành công',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },

    async onSucceed() {
      const user = await this.verify()
      if (user) {
        this.user = user
        if (this.user.changePasswordAt) {
          this.$router.go(0)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
